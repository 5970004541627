<template>
  <section class="container">
    <h2>Wiki Index</h2>
    <ul>
      <li
        v-for="wiki in wikiIndex"
        :key="wiki"
      >
        <router-link :to="{ name: 'wiki home', params: {wiki} }">
          {{ wiki }}
        </router-link>
      </li>
    </ul>
  </section>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('wiki')

export default {
  name: 'WikiIndex',
  computed: {
    ...mapState([
      'wikiIndex'
    ])
  },
  async mounted() {
    this.loadIndex()
  },
  methods: {
    ...mapActions([
      'loadIndex'
    ])
  }
}
</script>

<style>

</style>
